import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import MDBox from "components/Basics/MDBox";
import FormAction from "redux-react/actions/formAction";
import Message from "../Message";

const ConversationTab = ({ phoneCall, resource }) => {
	const dispatch = useDispatch();
	const [conversation, setConversation] = useState({ messages: [] });

	useEffect(() => {
		const fID = phoneCall?.fID;
		if (fID) {
			dispatch(
				FormAction.getItemsFromCollection(
					resource,
					{
						query: {
							fID,
							active: { $in: [true, false, null] }
						}
					},
					res => {
						if (res.items.length > 0) {
							setConversation(res.items[0]);
						}
					}
				)
			);
		}
	}, [phoneCall?.fID]);

	return (
		<MDBox
			borderRadius="md"
			p={1}
			mt={1}
			style={{
				position: "relative",
				backgroundColor: "rgb(236 236 236)",
				overflowY: "auto"
			}}
		>
			{conversation.messages.map((message, index) => (
				<Message key={index} message={message} />
			))}
		</MDBox>
	);
};

export default ConversationTab;
