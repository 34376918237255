import { display } from "redux-react/reducers/snackBarReducer";
import { api } from "./api";

const actions = {
	/**
	 * Allow to update a resource on the backend
	 * @param {string} resource the resource code like "message"
	 * @param {string} resourceID the id of the resource
	 * @param {object} resourceUpdate the updates to bring to the resource
	 * @param {function} onSuccess the function to call on success
	 */
	updateResource: (resource, resourceID, resourceUpdate, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "PUT",
					url: `/api/v1/resource/${resource}/${resourceID}`,
					data: resourceUpdate,
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error ?? "none"
							})
						);
					}
				})
			);
		};
	}
};

export default actions;
