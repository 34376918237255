/* eslint-disable no-underscore-dangle */

import lod_ from "lodash";
import { Badge, Icon, Menu, MenuItem } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDButton from "components/Basics/MDButton";
import { useState } from "react";

/**
 * Resource events menu
 * @param {*} param0
 * @returns {JSX.Element} - Resource events menu
 */
const ResourceEventsMenu = ({ resource, selectedRows, setSelectedRows, performAction }) => {
	if (lod_.isNil(resource) || lod_.isEmpty(resource)) {
		return null;
	}

	const events = resource.events ?? [];

	const [anchorElMenu, setAnchorElMenu] = useState(null);

	return (
		<MDBox>
			{/* Menu's buttons */}
			<Badge
				badgeContent={selectedRows.length}
				color="error"
				anchorOrigin={{
					vertical: "top",
					horizontal: "right"
				}}
			>
				<MDButton
					disabled={lod_.isEmpty(events)}
					variant="contained"
					color="dark"
					onClick={e => {
						setAnchorElMenu(e.currentTarget);
					}}
				>
					<Icon>list_alt</Icon>&nbsp;Actions ressource
				</MDButton>
			</Badge>

			{/* Menu */}
			<Menu
				anchorEl={anchorElMenu}
				open={Boolean(anchorElMenu)}
				onClose={() => setAnchorElMenu(null)}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center"
				}}
			>
				{/* Actions */}
				{events.map((event, index) => {
					return (
						<MenuItem
							disabled={selectedRows.length < 1}
							key={index}
							onClick={() => {
								setAnchorElMenu(null);
								setSelectedRows([]);
								performAction(event, selectedRows);
							}}
						>
							{event.icon && (
								<>
									<Icon fontSize="medium">{event.icon}</Icon>&nbsp;
								</>
							)}
							{event.description}
						</MenuItem>
					);
				})}
			</Menu>
		</MDBox>
	);
};

export default ResourceEventsMenu;
