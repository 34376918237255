import { api } from "./api";

const actions = {
	performAction: (data, onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/resource/action/perform`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						onFailure(err.response?.data?.error ?? err);
					}
				})
			);
		};
	}
};

export default actions;
